import { Toggle } from "rsuite";

const FantasilandiaSelectBox = ({ ...props }) => {
  return (
    <div className="">
      <div className="col-12 py-2 bold-300 mt-3">Selección de cajas</div>
      <hr />
      <Toggle
        checked={props.data.fantasilandia_select_box}
        onChange={(data) => props.onChange({ fantasilandia_select_box: data })}
      />
      <span className="ms-3 size-09">Seleccionar caja</span>
    </div>
  );
};

export default FantasilandiaSelectBox;
