import { Button, SelectPicker } from "rsuite";
import ModalComponent from "../../../../components/Modal/Modal";
import AdminTemplates from "./AdminTemplates";
import { useState } from "react";
import ModuleService from "../../../../services/module.service";
import { _pushToastMessage } from "../../../../helpers";

const Templates = ({
  selectedTemplate,
  templates,
  _handleSelectTemplate,
  _getTemplates,
}: any) => {
  const [adminTemplates, setAdminTemplates] = useState<any>({
    open: false,
  });
  const [deleteTemplateModal, setDeleteTemplateModal] = useState<any>({
    open: false,
    data: { id: 0 },
  });
  const [deleteSelectedTemplateModal, setDeleteSelectedTemplateModal] =
    useState<any>({
      open: false,
      data: [],
    });

  const _handleCloseAndUpdateTemplates = () => {
    setAdminTemplates({ ...adminTemplates, open: false });
    _getTemplates();
  };

  const _handleDeleteTemplate = async () => {
    try {
      await new ModuleService().deleteTemplate(deleteTemplateModal.data.id);
      setDeleteTemplateModal({ ...deleteTemplateModal, open: false });
      _handleCloseAndUpdateTemplates();
      _pushToastMessage({
        header: "Éxito",
        text: "Template eliminado correctamente",
        type: "success",
      });
    } catch (e: any) {
      console.log(e);
      _pushToastMessage({
        header: "Error",
        text: "Error al eliminar el template",
        type: "error",
      });
    }
  };

  const _handleDeleteSelectedTemplate = async () => {
    try {
      await new ModuleService().deleteSelectedTemplate(
        deleteSelectedTemplateModal.data
      );
      setDeleteSelectedTemplateModal({ ...deleteTemplateModal, open: false });
      _handleCloseAndUpdateTemplates();
      _pushToastMessage({
        header: "Éxito",
        text: "Templates eliminados correctamente",
        type: "success",
      });
    } catch (e: any) {
      console.log(e);
      _pushToastMessage({
        header: "Error",
        text: "Error al eliminar los templates",
        type: "error",
      });
    }
  };

  return (
    <div className="row mb-4">
      <div className="col-12 driver-select-template">
        <div className="size-08 bold-300">Templates</div>
        <SelectPicker
          value={selectedTemplate}
          className="w-100"
          data={templates}
          onChange={_handleSelectTemplate}
          placeholder="Selecciona un template"
          valueKey="id"
          labelKey="name"
        />
      </div>
      <div className="col-12 text-end mt-3">
        <Button
          appearance="primary"
          onClick={() => setAdminTemplates({ ...adminTemplates, open: true })}
        >
          Administrar templates
        </Button>

        <ModalComponent
          open={deleteTemplateModal.open}
          size="md"
          title="Administrar templates"
          handleClose={() => {
            setDeleteTemplateModal({ ...deleteTemplateModal, open: false });
            setAdminTemplates({ ...adminTemplates, open: true });
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                ¿Seguro que desea eliminar este template?
              </div>
              <div className="col-12 text-end mt-3">
                <Button
                  className="me-3"
                  appearance="primary"
                  onClick={() => {
                    setDeleteTemplateModal({
                      ...deleteTemplateModal,
                      open: false,
                    });
                    setAdminTemplates({ ...adminTemplates, open: true });
                  }}
                >
                  Cancelar
                </Button>
                <Button appearance="primary" onClick={_handleDeleteTemplate}>
                  Eliminar
                </Button>
              </div>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          open={deleteSelectedTemplateModal.open}
          size="md"
          title="Administrar templates"
          handleClose={() => {
            setDeleteSelectedTemplateModal({
              ...deleteSelectedTemplateModal,
              open: false,
            });
            setAdminTemplates({ ...adminTemplates, open: true });
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                ¿Seguro que desea eliminar todos los template seleccionados?
              </div>
              <div className="col-12 text-end mt-3">
                <Button
                  className="me-3"
                  appearance="primary"
                  onClick={() => {
                    setDeleteSelectedTemplateModal({
                      ...deleteSelectedTemplateModal,
                      open: false,
                    });
                    setAdminTemplates({ ...adminTemplates, open: true });
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  appearance="primary"
                  onClick={_handleDeleteSelectedTemplate}
                >
                  Eliminar
                </Button>
              </div>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          open={adminTemplates.open}
          size="md"
          title="Administrar templates"
          handleClose={() =>
            setAdminTemplates({ ...adminTemplates, open: false })
          }
        >
          <AdminTemplates
            data={templates}
            closeAndUpdateData={_handleCloseAndUpdateTemplates}
            onDelete={(event: {
              event: { open: boolean; data: { id: number } };
            }) => {
              setAdminTemplates({ ...adminTemplates, open: false });
              setDeleteTemplateModal({ ...event });
            }}
            onDeleteSelected={(event: {
              event: { open: boolean; data: { id: number } };
            }) => {
              setAdminTemplates({ ...adminTemplates, open: false });
              setDeleteSelectedTemplateModal({ ...event });
            }}
          />
        </ModalComponent>
      </div>
    </div>
  );
};

export default Templates;
