import { useState } from "react";
import { MdDeleteOutline, MdOutlineEdit, MdOutlineSave } from "react-icons/md";
import { Button, Checkbox, Input } from "rsuite";
import WhisperComponent from "../../../../components/WhisperComponent";
import ModuleService from "../../../../services/module.service";
import { useDispatch } from "react-redux";
import { loading_off, loading_on } from "../../../../redux/actions/loader";
import { _pushToastMessage } from "../../../../helpers";

const TemplateItem = ({
  item,
  closeAndUpdateData,
  onDelete,
  setSelectedList,
  selectedList,
}: {
  item: { name: string; id: number };
  closeAndUpdateData: () => void;
  selectedList: number[];
  setSelectedList: (ids: number[]) => void;
  onDelete: ({ open, data }: { open: boolean; data: { id: number } }) => void;
}) => {
  const [state, setState] = useState({
    name: item.name,
    id: item.id,
    isEditing: false,
    checked: false,
  });

  const dispatch: any = useDispatch();

  const _handleOnSave = async () => {
    try {
      dispatch(loading_on());
      await new ModuleService().editTemplate({
        ...item,
        name: state.name,
      });
      setState({ ...state, isEditing: false });
      _pushToastMessage({
        type: "success",
        text: "Plantilla Actualizada",
        header: "Éxito",
      });
      dispatch(loading_off());
      closeAndUpdateData();
    } catch (e: any) {
      dispatch(loading_off());
      _pushToastMessage({
        type: "error",
        text: "Error al actualizar la plantilla",
        header: "Error",
      });
    }
  };

  const handleList = (id: number, checked: boolean) => {
    if (!checked) {
      setSelectedList(selectedList.filter((item) => item !== id));
    } else {
      setSelectedList([...selectedList, id]);
    }
  };
  return (
    <div className="row w-100 m-0 py-0">
      <div className="col-1 d-flex align-items-center justify-content-center">
        <Checkbox
          checked={state.checked}
          onChange={() => {
            setState({ ...state, checked: !state.checked });
            handleList(item.id, !state.checked);
          }}
        />
      </div>
      {!state.isEditing && (
        <div
          className="col-8 align-items-center d-flex"
          style={{ overflow: "hidden" }}
        >
          {state.name}
        </div>
      )}
      {state.isEditing && (
        <div className="col-8 px-2 align-items-center d-flex">
          <Input
            placeholder="No hay Cajas Disponibles"
            value={state?.name}
            onChange={(value: any) => setState({ ...state, name: value })}
          />
        </div>
      )}
      <div className="col-3 d-flex justify-content-between align-items-center">
        <WhisperComponent text="Editar">
          <button
            className="btn size-13 text-secondary"
            onClick={() => setState({ ...state, isEditing: !state.isEditing })}
          >
            <MdOutlineEdit />
          </button>
        </WhisperComponent>
        <WhisperComponent text="Guardar">
          <button
            disabled={!state.isEditing}
            className="btn size-13 text-secondary"
            onClick={_handleOnSave}
          >
            <MdOutlineSave />
          </button>
        </WhisperComponent>
        <WhisperComponent text="Eliminar">
          <button
            className="btn size-13 text-danger"
            onClick={() => onDelete({ open: true, data: { id: item.id } })}
          >
            <MdDeleteOutline />
          </button>
        </WhisperComponent>
      </div>
      <hr className="p-0 m-0" style={{ opacity: "0.1" }} />
    </div>
  );
};

const AdminTemplates = ({
  data,
  closeAndUpdateData,
  onDelete,
  onDeleteSelected,
}: any) => {
  const [selectedList, setSelectedList] = useState([]) as any[];

  return (
    <div className="container-fluid">
      <div className="w-100" style={{ maxHeight: "50vh", overflow: "auto" }}>
        {data?.map((item: any) => (
          <TemplateItem
            key={item.id}
            item={item}
            closeAndUpdateData={closeAndUpdateData}
            onDelete={onDelete}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
          />
        ))}
      </div>
      <div className="row mt-5">
        <div className="col-12 text-end">
          <Button
            className="me-3"
            appearance="primary"
            onClick={() => onDeleteSelected({ open: true, data: selectedList })}
            disabled={selectedList.length === 0}
          >
            Eliminar seleccionados
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdminTemplates;
