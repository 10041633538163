/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Modal, SelectPicker } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";
import ModuleService from "../../services/module.service";
import TerminalService from "../../services/terminal.service";
import { TerminalSchema } from "../../validations/terminal.validations";
import { ReactComponent as Box } from "../../assets/img/svg/box-line.svg";
import { comdoors } from "../../helpers/com_doors";

const FormTerminal = (props: any) => {
  const { type, data, handleClose } = props;
  const [state, setState] = useState<any>({
    username: "",
    name: "",
    door_com_number: "",
    qrcode_com_number: "",
    password: "",
    cpassword: "",
    address: "",
    company: "",
    module: "",
    validations: {},
  });
  const dispatch: any = useDispatch();

  const Terminal = new TerminalService();
  const Company = new CompanyService();
  const Module = new ModuleService();

  useEffect(() => {
    _loadData();
  }, []);

  const _loadData = async () => {
    let companyResponse: any = [];
    let moduleResponse: any = [];
    try {
      if (type === "view") {
        setState({ ...state, ...data, companies: companyResponse.data });
      }

      if (type === "create") {
        dispatch(loading_on());
        companyResponse = await Company.selectList();
        moduleResponse = await Module.selectList();
        const modules = moduleResponse.data.filter(
          (module: any) => !module.is_base_module
        );
        
        setState({
          ...state,
          companies: companyResponse.data,
          modules: modules,
        });
      }

      if (type === "update") {
        dispatch(loading_on());
        companyResponse = await Company.selectList();
        moduleResponse = await Module.selectList();
        const payload: any = { ...state, ...data };
        payload.company = data?.company?.id;
        payload.module = data?.module?.id;
        payload.password = "";
        payload.companies = companyResponse.data;
        payload.modules = moduleResponse.data;

        if (payload.modules?.length > 0) {
          payload.modules = payload.modules.filter(
            (module: any) => !module.is_base_module
          );
        }

        if (payload.boxes) {
          payload.boxes.sort((a: any, b: any) => {
            if (a.box_type_id > b.box_type_id) return 1;
            if (a.box_type_id < b.box_type_id) return -1;
            return 0;
          });
        }
        setState({ ...payload });
      }

      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(loading_off());
    }
  };

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    let data: any = value;
    if (name === "username") {
      data = data.trim().replace(" ", "").toLowerCase();
    }

    if (name === "door_com_number" || name === "qrcode_com_number") {
      data = data.toUpperCase();
    }

    state[name] = data;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();
    if (type === "view") {
      handleClose();
      return;
    }

    let validations: any = await validate(TerminalSchema, state);

    if (state.password !== state.cpassword) {
      validations = validations || {};
      validations.password = ["Las contraseñas deben ser las mismas"];
      validations.cpassword = ["Las contraseñas deben ser las mismas"];
    }

    if (type === "update") {
      if (!state.password || state.password === "") {
        delete validations.password;
        delete validations.cpassword;
      }
    }

    if (validations !== false && Object.keys(validations).length > 0) {
      setState({ ...state, validations });
      return;
    }

    setState({ ...state, validations });

    const payload: any = {
      username: state.username,
      name: state.name,
      address: state.address,
      password: state.password,
      door_com_number: state.door_com_number,
      qrcode_com_number: state.qrcode_com_number,
      company_id: state.company,
      module_id: state.module,
    };

    dispatch(loading_on());
    try {
      if (type === "create") {
        await Terminal.create(payload);
      }

      if (type === "update") {
        payload.id = state.id;
        await Terminal.update(payload);
      }
    } catch (e: any) {
      _handleError(
        e,
        `No fue posible ${
          type === "create" ? "crear" : "actualizar"
        } el terminal`
      );
      if (e.response?.status === 422) {
        setState({ ...state, validations: e.response.data });
      }
      dispatch(loading_off());
      return;
    }

    handleClose(true);
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit} autoComplete="none">
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">
              Nombre de usuario del terminal
            </label>
            {type && type === "view" ? (
              <div className="size-08 ms-2">{state.username}</div>
            ) : (
              <Input
                placeholder="Nombre de usuario"
                value={state.username}
                autoComplete="off"
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "username", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="username"
            />
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Nombre del terminal</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.name}</div>
            ) : (
              <Input
                placeholder="Nombre del terminal"
                value={state.name}
                autoComplete="off"
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "name", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="name"
            />
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Dirección</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.address}</div>
            ) : (
              <Input
                placeholder="Dirección"
                value={state.address}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "address", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="address"
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Contraseña</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">***********</div>
            ) : (
              <Input
                placeholder="**********"
                value={state.password}
                type="password"
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "password", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="password"
            />
          </div>
          {type !== "view" && (
            <div className="col-6 mb-2">
              <label className="size-08 bold-300 ms-1">
                Confirmar Contraseña
              </label>
              {type && type !== "view" && (
                <Input
                  placeholder="**********"
                  value={state.cpassword}
                  type="password"
                  onChange={(value: any) =>
                    _handleOnChange({ target: { name: "cpassword", value } })
                  }
                />
              )}
              <ErrorValidation
                className="text-end size-08"
                validations={state.validations}
                name="cpassword"
              />
            </div>
          )}

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Com Puerta</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.door_com_number}
              </div>
            ) : (
              <SelectPicker
                className="w-100"
                placeholder="Seleccionar puerto COM de las puertas"
                value={state.door_com_number}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "door_com_number", value },
                  })
                }
                data={comdoors}
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="door_com_number"
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Com Qrcode</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.qrcode_com_number}
              </div>
            ) : (
              <SelectPicker
                className="w-100"
                placeholder="Seleccionar puerto COM del lector QR"
                value={state.qrcode_com_number}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "qrcode_com_number", value },
                  })
                }
                data={comdoors}
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="qrcode_com_number"
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Modulo</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.module?.name}
              </div>
            ) : (
              <SelectPicker
                className="w-100"
                locale={{
                  placeholder: "Selecciona un modulo",
                  searchPlaceholder: "Buscar",
                  emptyMessage: "Ningun modulo encontrado",
                  noResultsText: "Ningun modulo encontrado",
                }}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "module", value },
                  })
                }
                value={state.module}
                data={state.modules || []}
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="module"
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Empresa</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.company?.name}
              </div>
            ) : (
              <SelectPicker
                className="w-100"
                locale={{
                  placeholder: "Selecciona una empresa",
                  searchPlaceholder: "Buscar",
                  emptyMessage: "Ninguna empresa encontrada",
                  noResultsText: "Ninguna empresa encontrada",
                }}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "company", value },
                  })
                }
                value={state.company}
                data={state.companies || []}
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="company"
            />
          </div>
        </div>
        {type === "view" && (
          <div className="row mt-3">
            <div className="col-12 bold-300 size-09">Cajas</div>
            {data.boxes &&
              data.boxes.map((box: any) => (
                <>
                  {box.is_enabled && (
                    <div className="col-4 size-08 p-2" key={box.id}>
                      <Box
                        className="me-2"
                        style={{ width: "15px", height: "15px" }}
                      />{" "}
                      {box.box_type?.name}
                    </div>
                  )}
                </>
              ))}
          </div>
        )}
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormTerminal;
