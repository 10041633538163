import { Button } from "rsuite";
import { v4 } from "uuid";
import ErrorValidation from "../components/ErrorValidation";

interface IWebHookHeaders {
  id?: number | string;
  key: string;
  value: string;
}
interface IWebHook {
  handleOnChange: (event: any) => void;
  value: string;
  headers: IWebHookHeaders[];
  setHeaders: (headers: IWebHookHeaders[]) => void;
  webhookValidations: any;
}

const Webhook = ({
  handleOnChange,
  value,
  headers,
  setHeaders,
  webhookValidations,
}: IWebHook) => {
  const handleAddNewHeader = () => {
    const newHeaders = [...headers];
    newHeaders.push({ id: v4(), key: "", value: "" });
    setHeaders(newHeaders);
  };

  return (
    <>
      <div className="col-12 text-end">
        <hr />
        <Button appearance="primary" onClick={handleAddNewHeader}>
          + Agregar Header
        </Button>
      </div>

      <div className="col-12 size-08">
        <span className="bold">Webhook</span>
        <input
          type="text"
          className="form-control size-11"
          name="webhook"
          value={value}
          onChange={handleOnChange}
          placeholder="Ingresa url del webhook"
        />
        <ErrorValidation
          className="text-end size-08"
          validations={webhookValidations}
          name={`webhook`}
        />
      </div>

      {headers.map((header, index) => (
        <div className="col-12 size-08 my-2" key={header.id}>
          <div className="row">
            <div className="col-5 d-flex flex-column aling-items-center">
              <span className="bold">Nombre</span>
              <input
                type="text"
                className="form-control size-11"
                name="key"
                value={header.key}
                onChange={(e) => {
                  const newHeaders = [...headers];
                  newHeaders[index].key = e.target.value;
                  setHeaders(newHeaders);
                }}
                placeholder="Ingresa el nombre del header"
              />
              <ErrorValidation
                className="text-end size-08"
                validations={webhookValidations}
                name={`headers[${index}].key`}
              />
            </div>
            <div className="col-5 d-flex flex-column aling-items-center">
              <span className="bold">Valor</span>
              <input
                type="text"
                className="form-control size-11"
                name="value"
                value={header.value}
                onChange={(e) => {
                  const newHeaders = [...headers];
                  newHeaders[index].value = e.target.value;
                  setHeaders(newHeaders);
                }}
                placeholder="Ingresa el valor del header"
              />
              <ErrorValidation
                className="text-end size-08"
                validations={webhookValidations}
                name={`headers[${index}].value`}
              />
            </div>
            <div className="col-2 d-flex flex-column aling-items-center justify-content-end">
              <Button
                appearance="default"
                onClick={() => {
                  const newHeaders = [...headers];
                  newHeaders.splice(index, 1);
                  setHeaders(newHeaders);
                }}
              >
                Eliminar
              </Button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Webhook;
