import { Input, Toggle } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";

const EventsPickupForm = ({ onChange, data }: any) => {
  return (
    <div className="row">
      <div className="col-12 py-2 bold-300 mt-3">PIN</div>
      <hr className="w-75" />
      <div className="col-12 py-2 driver-pickup-pin">
        <Toggle defaultChecked={data?.events_pickup_pinpad_required} onChange={(value) => onChange({ target: { name: "events_pickup_pinpad_required", value } })} />
          <span className="ms-3 size-09">Desea que el usuario ingrese un PIN para retirar sus pertenencias?</span>
      </div>

      <div className="col-12 py-2 bold-300 mt-3">
        Retiro Parcial
      </div>
      <hr />
      <div className="col-12 mb-2 driver-pickup-text-1">
        <label className="size-08 bold-300 ms-1">Titulo</label>
        <Input
          placeholder="Retiro parcial"
          value={data?.events_pickup_partial_title}
          onChange={(value: any) =>
            onChange({ target: { name: "events_pickup_partial_title", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_pickup_partial_title"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 mb-2 driver-pickup-text-1">
        <label className="size-08 bold-300 ms-1">Descripción</label>
        <Input
          placeholder="Puedes abrir el casillero para retirar o guardar algo y su código sigue válido."
          value={data?.events_pickup_partial_text}
          onChange={(value: any) =>
            onChange({ target: { name: "events_pickup_partial_text", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_pickup_partial_text"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">
        Retiro Total
      </div>
      <hr />
      <div className="col-12 mb-2 driver-pickup-text-1">
        <label className="size-08 bold-300 ms-1">Titulo</label>
        <Input
          placeholder="Retiro total"
          value={data?.events_pickup_full_title}
          onChange={(value: any) =>
            onChange({ target: { name: "events_pickup_full_title", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_pickup_full_title"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 mb-2 driver-pickup-text-1">
        <label className="size-08 bold-300 ms-1">Descripción</label>
        <Input
          placeholder="Abre la caja y retira todos tus items. Tu reserva sera finalizada al cerrar la puerta."
          value={data?.events_pickup_full_text}
          onChange={(value: any) =>
            onChange({ target: { name: "events_pickup_full_text", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_pickup_full_text"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">
        Retiro - Confirmación
      </div>
      <hr />
      <div className="col-12 mb-2 driver-pickup-text-1">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Guarda los articulos y cierra la puerta"
          value={data?.events_pickup_step1_text}
          onChange={(value: any) =>
            onChange({ target: { name: "events_pickup_step1_text", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_pickup_step1_text"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default EventsPickupForm;
