import { useEffect, useState } from "react";
import { Button, Nav } from "rsuite";
import FantasilandiaSelectBox from "./FantasilandiaSelectBox";

const FantasilandiaTextForm = ({
  onConfirm,
  module,
}: {
  onConfirm: (data: any) => void;
  module: any;
}) => {
  const [state, setState] = useState({
    save_config_as_template: false,
    validations: {},
  });
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    setState({
      ...module,
    });
  }, [module]);

  const _handleValidations = () => {
    onConfirm({
      ...state,
      save_config_as_template: false,
      validations: {},
    });
  };

  const _handleOnChangeState = (data: any) => {
    setState({
      ...state,
      ...data,
    });
  };
  return (
    <div className="container py-3 px-5">
      <Nav
        appearance="subtle"
        activeKey={activeKey}
        onSelect={(value) => setActiveKey(value)}
      >
        <Nav.Item eventKey="1" className="driver-nav-1">
          Cajas
        </Nav.Item>
      </Nav>

      <div className="py-4">
        {activeKey === "1" && (
          <FantasilandiaSelectBox
            onChange={_handleOnChangeState}
            data={state}
          />
        )}
      </div>

      <div className="w-100 text-end driver-save-configs">
        <Button appearance="primary" onClick={_handleValidations}>
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default FantasilandiaTextForm;
