import * as yup from "yup";

export const DynamicEventsSchema = yup.object().shape({
  events_home_pickup_list_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
    events_pickup_partial_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
    events_pickup_partial_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
    events_pickup_full_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
    events_pickup_full_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_home_map_btn_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_home_video_btn_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_company_map_btn_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_company_video_btn_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_home_delivery_list_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_home_first_btn_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_home_second_btn_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_home_third_btn_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_home_pickup_list: yup
    .array()
    .nullable()
    .min(1, "Debes agregar al menos un paso")
    .required("Este campo es requerido"),
  events_home_delivery_list: yup
    .array()
    .nullable()
    .min(1, "Debes agregar al menos un paso")
    .required("Este campo es requerido"),
  events_delivery_step1_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_delivery_step2_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_delivery_single_success_step_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_delivery_single_fail_step_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_pickup_step1_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_my_locker_list_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_my_locker_list: yup
    .array()
    .nullable()
    .min(1, "Debes agregar al menos un paso")
    .required("Este campo es requerido"),
  events_my_locker_confirmation_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_my_locker_confirmation_subtitle: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_pinpad_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_help_emails: yup
    .array()
    .nullable()
    .min(1, "Debes agregar al menos un correo electrónico")
    .required("Este campo es requerido"),
  events_help_phones: yup
    .array()
    .nullable()
    .min(1, "Debes agregar al menos un teléfono")
    .required("Este campo es requerido"),
  events_help_text: yup.string().nullable().required("Este campo es requerido"),
  events_help_success_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_help_failed_text: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  events_company_max_reservations_enabled: yup.boolean().nullable(),
  events_company_max_reservations: yup
    .string()
    .nullable()
    .when("events_company_max_reservations_enabled", {
      is: true,
      then: yup.string().required("Este campo es requerido"),
    }),
  events_company_max_reservations_message: yup
    .string()
    .nullable()
    .when("events_company_max_reservations_enabled", {
      is: true,
      then: yup.string().required("Este campo es requerido"),
    }),
  events_company_info_enabled: yup.boolean().nullable(),
  events_company_max_reservations_message_one: yup
    .string()
    .nullable()
    .when("events_company_info_enabled", {
      is: true,
      then: yup.string().required("Este campo es requerido"),
    }),
  events_company_max_reservations_message_two: yup
    .string()
    .nullable()
    .when("events_company_info_enabled", {
      is: true,
      then: yup.string().required("Este campo es requerido"),
    }),
  events_qrcode_dynamic_url: yup.boolean().nullable(),
  events_qrcode_info_1: yup
    .string()
    .nullable()
    .when("events_qrcode_dynamic_url", {
      is: true,
      then: yup.string().required("Este campo es requerido"),
    }),
  events_qrcode_info_2: yup
    .string()
    .nullable()
    .when("events_qrcode_dynamic_url", {
      is: true,
      then: yup.string().required("Este campo es requerido"),
    }),
  events_qrcode_dynamic_url_path: yup
    .string()
    .nullable()
    .when("events_qrcode_dynamic_url", {
      is: true,
      then: yup.string().required("Este campo es requerido"),
    }),
});

export type DynamicEventsType = yup.InferType<typeof DynamicEventsSchema>;

export const DynamicEventListSchema = yup.object().shape({
  id: yup.string().nullable().required("Este campo es requerido"),
  title: yup.string().nullable().required("Este campo es requerido"),
});

export type DynamicEventListType = yup.InferType<typeof DynamicEventListSchema>;

export const DynamicEventEmailSchema = yup.object().shape({
  id: yup.string().nullable().required("Este campo es requerido"),
  title: yup.string().nullable().required("Este campo es requerido"),
});

export type DynamicEventEmailType = yup.InferType<
  typeof DynamicEventEmailSchema
>;

export const DynamicEventPhoneSchema = yup.object().shape({
  phone: yup
    .string()
    .min(11)
    .max(11)
    .nullable()
    .required("Este campo es requerido"),
});

export type DynamicEventPhoneType = yup.InferType<
  typeof DynamicEventPhoneSchema
>;
