/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loading_off, loading_on } from "../../redux/actions/loader";
import DefaultTemplate from "../../templates/DefaultTemplate";
import Header from "./Header";

import { _handleError } from "../../helpers/errors";
import SelectModuleForm from "../../forms/DynamicModuleForms/page/home/SelectModuleForm";
import { useNavigate, useParams } from "react-router-dom";
import TerminalService from "../../services/terminal.service";
import ResidentialTextForm from "../../forms/DynamicModuleForms/modules/Residential";
import PickupTextForm from "../../forms/DynamicModuleForms/modules/Pickup";
import LendingtextForm from "../../forms/DynamicModuleForms/modules/Lending";
import PickupWithoutReservationTextForm from "../../forms/DynamicModuleForms/modules/PickupWithoutReservation";
import { _pushToastMessage } from "../../helpers";
import ModuleAssetsService from "../../services/module_assets.service";
import { residentialStateSchema } from "../../forms/DynamicModuleForms/modules/Residential/Schema";
import { pickupStateSchema } from "../../forms/DynamicModuleForms/modules/Pickup/Schema";
import { pickupWithoutReservationStateSchema } from "../../forms/DynamicModuleForms/modules/PickupWithoutReservation/Schema";
import { lendingStateSchema } from "../../forms/DynamicModuleForms/modules/Lending/Schema";
import EventsTextForm from "../../forms/DynamicModuleForms/modules/Events";
import { eventsStateSchema } from "../../forms/DynamicModuleForms/modules/Events/Schema";
import { useSelector } from "react-redux";
import { fantasilandiaStateSchema } from "../../forms/DynamicModuleForms/modules/Fantasilandia/Schema";
import FantasilandiaTextForm from "../../forms/DynamicModuleForms/modules/Fantasilandia";

const DynamicModule = () => {
  const [data, setData] = useState<any>([]);
  const [state, setState] = useState<any>({
    module_assets: null,
    terminal: null,
  });
  const { messenger } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    _getData();
  }, []);

  useEffect(() => {
    switch (messenger?.action) {
      case "select-module-event":
        const module = data.find((item: any) => item.slug === "events-module");
        if (!module) return;
        _handleOnChangeState({ target: { value: module.id } });
        break;
      default:
        break;
    }
  }, [messenger]);

  const _getData = async () => {
    dispatch(loading_on());
    const { id: terminal_id } = params;
    try {
      const response: any = await new TerminalService().getDynamicTerminalData(
        terminal_id
      );

      const available_slugs = [
        "residential-module",
        "residential-offline-module",
        "pickup-module",
        "pickup-box-module",
        "lending-module",
        "events-module",
        "fantasilandia-module",
      ];

      if (response?.data?.terminal?.module?.slug !== "dynamic-module") {
        dispatch(loading_off());
        _pushToastMessage({
          header: "Error",
          text: "Módulo no encontrado",
          type: "error",
        });
        navigate("/terminals");
        return;
      }

      const available_modules = response?.data?.modules.filter((item: any) =>
        available_slugs.includes(item.slug)
      );

      setData(available_modules);

      let module_assets = response?.data?.terminal?.assets;
      let loaded_assets = null;
      if (module_assets) {
        module_assets = { ...module_assets.assets, id: module_assets.id };
        loaded_assets = JSON.parse(JSON.stringify(module_assets));
      }
      setState({
        ...state,
        ...response.data,
        terminal_id,
        module_assets,
        loaded_assets,
        terminal: response?.data?.terminal,
      });

      dispatch(loading_off());
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  const _handleOnChangeState = (e: any) => {
    const module = data.find((item: any) => item.id === e.target.value);
    if (!module) return;

    if (module?.slug === state.module_assets?.slug) return;

    let module_assets: any = {
      module_id: module?.id,
      slug: module?.slug,
      id: state.module_assets?.id,
    };

    if (state.loaded_assets?.slug === module?.slug) {
      setState({
        ...state,
        module_assets: { ...state.loaded_assets },
      });
      return;
    }

    switch (module?.slug) {
      case "residential-module":
      case "residential-offline-module":
        module_assets = {
          ...module_assets,
          ...residentialStateSchema,
        };
        break;
      case "pickup-module":
        module_assets = {
          ...module_assets,
          ...pickupStateSchema,
        };
        break;
      case "pickup-box-module":
        module_assets = {
          ...module_assets,
          ...pickupWithoutReservationStateSchema,
        };
        break;
      case "lending-module":
        module_assets = {
          ...module_assets,
          ...lendingStateSchema,
        };
        break;
      case "fantasilandia-module":
        module_assets = {
          ...module_assets,
          ...fantasilandiaStateSchema,
        };
        break;
      case "events-module":
        const company_config =
          state?.terminal?.company?.config?.setup?.events_config;
        let company_assets: any = {};
        if (company_config) {
          Object.keys(company_config).forEach((key: any) => {
            company_assets["events_company_" + key] = company_config[key];
          });
        }

        if (state?.terminal)
          module_assets = {
            ...module_assets,
            ...eventsStateSchema,
            ...company_assets,
          };
        break;
      default:
        break;
    }

    setState({
      ...state,
      module_assets: { ...module_assets },
    });
  };

  const _handleSubmitData = async (data: any) => {
    const { id: terminal_id } = params;
    try {
      dispatch(loading_on());
      if (!data?.id) {
        await new ModuleAssetsService().create({
          terminal_id,
          module_id: data?.module_id,
          assets: data,
          save_config_as_template: data.save_config_as_template,
        });

        _getData();

        _pushToastMessage({
          header: "Éxito",
          text: "Datos guardados correctamente",
          type: "success",
        });
        dispatch(loading_off());
        return;
      }

      await new ModuleAssetsService().update({
        terminal_id,
        module_id: data?.module_id,
        assets: data,
        id: data?.id,
        save_config_as_template: data.save_config_as_template,
      });

      _getData();
      _pushToastMessage({
        header: "Éxito",
        text: "Datos actualizados correctamente",
        type: "success",
      });
      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(loading_off());
    }
  };

  return (
    <DefaultTemplate>
      <div
        className="row mx-0 px-4 py-5 with-header driver-root"
        style={{ overflow: "auto" }}
      >
        <div className="default-template-box">
          <div className="row">
            <div className="col-12 justify-content-between align-items-center d-flex py-2 px-3 bold-300 line-border">
              <Header />
            </div>
            <div className="col-12 px-5 py-4 driver-select-module">
              <SelectModuleForm
                onChange={(value: any) =>
                  _handleOnChangeState({ target: { value } })
                }
                data={data}
                value={state?.module_assets?.module_id}
                className=""
              />
            </div>
            {(state?.module_assets?.slug === "residential-module" ||
              state.module_assets?.slug === "residential-offline-module") && (
              <ResidentialTextForm
                module={state.module_assets}
                onConfirm={_handleSubmitData}
              />
            )}

            {state.module_assets?.slug === "pickup-module" && (
              <PickupTextForm
                module={state.module_assets}
                onConfirm={_handleSubmitData}
              />
            )}

            {state.module_assets?.slug === "pickup-box-module" && (
              <PickupWithoutReservationTextForm
                module={state.module_assets}
                onConfirm={_handleSubmitData}
              />
            )}

            {state.module_assets?.slug === "lending-module" && (
              <LendingtextForm
                module={state.module_assets}
                onConfirm={_handleSubmitData}
              />
            )}

            {state.module_assets?.slug === "events-module" && (
              <EventsTextForm
                module={state.module_assets}
                onConfirm={_handleSubmitData}
              />
            )}

            {state.module_assets?.slug === "fantasilandia-module" && (
              <FantasilandiaTextForm
                module={state.module_assets}
                onConfirm={_handleSubmitData}
              />
            )}
          </div>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default DynamicModule;
