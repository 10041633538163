/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Input,
  MaskedInput,
  Modal,
  SelectPicker,
  TagPicker,
  Toggle,
} from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import { phoneMask } from "../../helpers/masks";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";
import CompanyTypeService from "../../services/company_type.service";
import {
  CompanyFeaturesWebHookSchema,
  CompanySchema,
} from "../../validations/company.validations";
import FeatureService from "../../services/feature.service";
import Webhook from "../../features/webhook";
import { BsInfoCircle } from "react-icons/bs";
import WhisperComponent from "../../components/WhisperComponent";

const FormCompany = (props: any) => {
  const { type, data, handleClose, users } = props;
  const dispatch: any = useDispatch();
  const CompanyType = new CompanyTypeService();
  const Features = new FeatureService();
  const Company = new CompanyService();

  const [state, setState] = useState<any>({
    name: "",
    phone: "",
    address: "",
    email: "",
    type_id: null,
    users: [],
    usersText: [],
    validations: {},
    webhookValidations: {},
    types: [],
    features: [],
    featuresList: [],
    featuresJson: {},
  });

  const [headers, setHeaders] = useState<any>([]);

  useEffect(() => {
    if (type !== "create") {
      const users: any = data.users.map((item: any) => item.id);
      const usersText: any = data.users.map((item: any) => item.name);

      const featuresJson: any = {};

      data.features.forEach((feature: any) => {
        const jsonData = JSON.parse(feature.pivot?.json);
        featuresJson[feature.slug] = { ...jsonData };
        if (jsonData?.headers) {
          setHeaders(jsonData?.headers);
        }
      });

      const features: any = data.features.map((item: any) => item.id);

      setState({
        ...state,
        ...data,
        features,
        featuresJson,
        users,
        usersText,
        type_id: data?.company_type?.id,
      });
    }
    _getData();
  }, []);

  const _getData = async () => {
    dispatch(loading_on());
    try {
      const response: any = await CompanyType.list();
      const types: any = response.data.map((item: any) => {
        return {
          value: item.id,
          label: item.name === "lending" ? "prestamos" : item.name,
        };
      });

      const responseFeatures: any = await Features.find("Company");
      const featuresList: any = responseFeatures.data;

      setState((prevState: any) => {
        return { ...prevState, types, featuresList };
      });
      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(loading_off());
    }
  };

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (type === "view") {
      handleClose();
    }

    const featuresToInstall: any = [];
    if (state.features?.length > 0) {
      state.featuresList?.forEach((item: any) => {
        if (state.features.includes(item.id)) {
          if (state.featuresJson[item.slug]) {
            featuresToInstall.push(state.featuresJson[item.slug]);
          } else {
            featuresToInstall.push({ id_feature: item.id, slug: item.slug });
          }
        }
      });
    }

    const validations: any = await validate(CompanySchema, state);

    let webhookValidations: any = false;

    const webhook_feature = state.featuresList.find(
      (item: any) => item.slug === "webhook_company"
    );

    const hasWebhookFeatureToInstall = featuresToInstall.find(
      (item: any) =>
        item?.slug === "webhook_company" ||
        item?.id_feature === webhook_feature?.id
    );


    if (hasWebhookFeatureToInstall) {
      webhookValidations = await validate(CompanyFeaturesWebHookSchema, {
        webhook: hasWebhookFeatureToInstall?.webhook,
        headers,
      });

      hasWebhookFeatureToInstall.headers = headers;
    }

    if (validations !== false || webhookValidations !== false) {
      setState({ ...state, validations, webhookValidations });
      return;
    }

    setState({ ...state, validations: {}, webhookValidations: {} });

    dispatch(loading_on());

    if (
      state.types
        .find((item: any) => parseInt(item.value) === parseInt(state.type_id))
        ?.label?.toLowerCase() !== "sameday" &&
      state.types
        .find((item: any) => parseInt(item.value) === parseInt(state.type_id))
        ?.label?.toLowerCase() !== "prestamos" &&
      state.types
        .find((item: any) => parseInt(item.value) === parseInt(state.type_id))
        ?.label?.toLowerCase() !== "residencial" &&
      state.types
        .find((item: any) => parseInt(item.value) === parseInt(state.type_id))
        ?.label?.toLowerCase() !== "ecommerce"
    ) {
      state.is_company_sender = false;
    }

    if (type === "create") {
      try {
        await Company.create({ ...state, features: featuresToInstall });
        _pushToastMessage({
          type: "success",
          header: "Éxito",
          text: "Empresa creada con éxito",
        });
      } catch (e: any) {
        _handleError(e, e.message);
        dispatch(loading_off());
        return;
      }
    }

    if (type === "update") {
      try {
        await Company.update({ ...state, features: featuresToInstall });
        _pushToastMessage({
          type: "success",
          header: "Éxito",
          text: "Empresa actualizada con éxito",
        });
      } catch (e: any) {
        _handleError(e, e.message);
        dispatch(loading_off());
        return;
      }
    }

    handleClose(true);
  };

  const _handleFeatureOnChange = ({
    feature_slug,
    name,
    value,
    id_feature,
  }: any) => {
    setState({
      ...state,
      featuresJson: {
        ...state.featuresJson,
        [feature_slug]: {
          ...state.featuresJson[feature_slug],
          slug: feature_slug,
          [name]: value,
          id_feature,
        },
      },
    });
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Nombre</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.name}</div>
            ) : (
              <Input
                placeholder="Nombre"
                value={state.name}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "name", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="name"
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Telefóno</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.phone}</div>
            ) : (
              <MaskedInput
                mask={phoneMask}
                value={state.phone}
                guide={true}
                showMask={true}
                keepCharPositions={true}
                placeholder={""}
                placeholderChar={"_"}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "phone", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="phone"
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Dirección</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.address}</div>
            ) : (
              <Input
                placeholder="Dirección"
                value={state.address}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "address", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="address"
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Email</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2">{state.email}</div>
            ) : (
              <Input
                placeholder="Email"
                value={state.email}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "email", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="email"
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Tipo</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.company_type?.name}
              </div>
            ) : (
              <SelectPicker
                className="w-100"
                locale={{
                  placeholder: "Selecciona un tipo",
                  searchPlaceholder: "Buscar",
                  emptyMessage: "Ningun tipo encontrado",
                  noResultsText: "Ningun tipo encontrado",
                }}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "type_id", value } })
                }
                value={state.type_id}
                data={state.types || []}
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="type_id"
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">
              {type === "view" ? "Usuarios" : "Usuarios responsables"}
            </label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.usersText.join(", ")}
              </div>
            ) : (
              <TagPicker
                className="w-100"
                locale={{
                  placeholder: "Selecciona uno o mas usuarios",
                  searchPlaceholder: "Buscar",
                  emptyMessage: "Ningun usuario encontrado",
                  noResultsText: "Ningun usuario encontrado",
                }}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "users", value } })
                }
                value={state.users}
                data={users || []}
              />
            )}
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">
              {type === "view" ? "Funciones" : "Funciones"}
            </label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.features.join(", ")}
              </div>
            ) : (
              <TagPicker
                className="w-100"
                locale={{
                  placeholder: "Selecciona una o mas funciones",
                  searchPlaceholder: "Buscar",
                  emptyMessage: "Ninguna función encontrada",
                  noResultsText: "Ningun función encontrada",
                }}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "features", value } })
                }
                value={state.features}
                data={state.featuresList || []}
                valueKey="id"
                labelKey="name"
              />
            )}
          </div>
          {state.types
            .find(
              (item: any) => parseInt(item.value) === parseInt(state.type_id)
            )
            ?.label?.toLowerCase() === "sameday" ||
          state.types
            .find(
              (item: any) => parseInt(item.value) === parseInt(state.type_id)
            )
            ?.label?.toLowerCase() === "prestamos" ||
          state.types
            .find(
              (item: any) => parseInt(item.value) === parseInt(state.type_id)
            )
            ?.label?.toLowerCase() === "residencial" ||
          state.types
            .find(
              (item: any) => parseInt(item.value) === parseInt(state.type_id)
            )
            ?.label?.toLowerCase() === "ecommerce" ? (
            <>
              <div className="col-6 mb-2">
                <label className="size-08 bold-300 ms-1">
                  <WhisperComponent
                    capitalizeOff
                    text={
                      state.types
                        .find(
                          (item: any) =>
                            parseInt(item.value) === parseInt(state.type_id)
                        )
                        ?.label?.toLowerCase() === "sameday"
                        ? "Accion sobre el correo enviado al cliente al momento de hacer reserva"
                        : "Accion sobre el correo enviado al ecommerce ao momento de hacer reserva"
                    }
                  >
                    Correos enviados por la empresa
                    <BsInfoCircle className="ms-3" />
                  </WhisperComponent>
                </label>
                {type && type === "view" ? (
                  <div className="size-08 ms-2 capitalize">
                    {state.is_company_sender ? "Sí" : "No"}
                  </div>
                ) : (
                  <Toggle
                    className="w-100"
                    checked={state.is_company_sender}
                    onChange={(value: any) =>
                      _handleOnChange({
                        target: { name: "is_company_sender", value },
                      })
                    }
                  />
                )}
              </div>
            </>
          ) : null}

          {/* Load Features */}

          {state.features.map((feature: any, index: number) => (
            <Fragment key={`feature-${index}`}>
              {state.featuresList.find(
                (item: any) => parseInt(item.id) === parseInt(feature)
              )?.slug === "webhook_company" ? (
                <>
                  {type && type === "view" ? (
                    <>
                      <div className="col-12 mb-2">
                        <label className="size-08 bold-300 ms-1">Webhook</label>
                        <div className="size-08 ms-2">
                          {state.featuresJson?.webhook_company?.webhook}
                        </div>
                      </div>

                      {state.featuresJson?.webhook_company?.headers && (
                        <div className="col-12 mb-2">
                          <label className="size-08 bold-300 ms-1">
                            Headers
                          </label>
                          <div className="ms-2">
                            {state.featuresJson?.webhook_company?.headers.map(
                              (header: any, index: number) => (
                                <div key={`header-${index}`}>
                                  <div className="size-08 ">
                                    <span className="bold">{header.key}</span>:{" "}
                                    {header.value}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <Webhook
                      handleOnChange={({ target }: any) =>
                        _handleFeatureOnChange({
                          feature_slug: "webhook_company",
                          name: target.name,
                          value: target.value,
                          id_feature: feature,
                        })
                      }
                      value={state.featuresJson?.webhook_company?.webhook}
                      key="webhook_company"
                      headers={headers}
                      setHeaders={setHeaders}
                      webhookValidations={state.webhookValidations}
                    />
                  )}
                </>
              ) : null}
            </Fragment>
          ))}
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormCompany;
