import { Input, Toggle } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";

const EventsQrcodeConfig = ({ onChange, data }: any) => {
  return (
    <div className="row">
      <div className="col-12 py-2 bold-300 mt-3">Qrcode con url dinamica</div>
      <hr className="w-75" />
      <div className="col-12 py-2 driver-pickup-pin">
        <Toggle
          defaultChecked={data?.events_qrcode_dynamic_url}
          onChange={(value) =>
            onChange({ target: { name: "events_qrcode_dynamic_url", value } })
          }
        />
        <span className="ms-3 size-09">
          Desea mostrar una url dinamica en el codigo qr?
        </span>
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Url</div>
      <hr />
      <div className="col-12 mb-2 driver-pickup-text-1">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Introduzca aquí la URL a la que le redirigirá el código QR"
          value={data?.events_qrcode_dynamic_url_path}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_qrcode_dynamic_url_path", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_qrcode_dynamic_url_path"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Textos informativos</div>
      <hr className="w-75" />
      <div className="col-12 mb-2 driver-pickup-text-1">
        <label className="size-08 bold-300 ms-1">Texto 1</label>
        <Input
          placeholder="Escanea el código"
          value={data?.events_qrcode_info_1}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_qrcode_info_1", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_qrcode_info_1"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 mb-2 driver-pickup-text-1">
        <label className="size-08 bold-300 ms-1">Texto 2</label>
        <Input
          placeholder="QR para registrarse"
          value={data?.events_qrcode_info_2}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_qrcode_info_2", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_qrcode_info_2"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default EventsQrcodeConfig;
